const config = {
	baseURL: process.env.REACT_APP_API_BASE_URL,
	endpoints: {
		customers_url: "/cstracking/customers",
		customer_regions_url: "/cstracking/customerregions",
		reason_codes_url: "/cstracking/reasoncodes",
		shipment_data_url: "/cstracking/shipments/search",
		metrics_url: "/cstracking/shipments/counts",
		shipment_update_url: "/cstracking/shipments",
		users: "/cstracking/users",
	},
	websocketURL: "wss://api-cstracking-dev.azurewebsites.net",
};

export default config;
