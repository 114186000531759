import moment from "moment";
import React from "react";

// Shared utility functions
export const formatDeliveryStatus = (status) => {
	if (!status) return "";
	const statusMap = {
		Intransit: "In Transit",
	};
	return statusMap[status] || status;
};

export const formatMultipleValues = (value) => {
	if (!value) return "";

	// Split the input string by "|", trim each item, and create a Set to filter unique values
	const uniqueValues = [...new Set(value.split("|").map((item) => item.trim()))];

	return uniqueValues.map((item, index) => (
		<React.Fragment key={index}>
			{index > 0 && <br />}
			{item}
		</React.Fragment>
	));
};

export const convertUTCTimeToLocalTime = (UTCDate) => {
	var stillUtc = moment.utc(UTCDate, "YYYYMMDDHHmmss").toDate();
	const formattedLocalDate = moment.utc(stillUtc).local().format("MM/DD/YYYY HH:mm:ss");

	return formattedLocalDate ? formattedLocalDate : "";
};

export const roundToTwoDecimalPlaces = (value) => {
	const number = Number(value);
	if (isNaN(number)) {
		throw new Error("Input is not a valid number");
	}
	return Number(number.toFixed(2));
};

export const removeDuplicateShipments = (shipments) => {
	const uniqueMap = new Map();

	shipments.forEach((shipment) => {
		// Use composite key of just DeliveryNumber and NBNumber
		const key = `${shipment.DeliveryNumber}-${shipment.NBNumber}`;

		// Keep the most recently updated record
		if (
			!uniqueMap.has(key) ||
			(shipment.UpdatedTime &&
				uniqueMap.get(key).UpdatedTime &&
				shipment.UpdatedTime > uniqueMap.get(key).UpdatedTime)
		) {
			uniqueMap.set(key, shipment);
		}
	});

	return Array.from(uniqueMap.values());
};
