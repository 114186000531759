import { MsalAuthProvider, LoginType } from "react-aad-msal";

// Msal Configurations
const config = {
	auth: {
		authority: "https://login.microsoftonline.com/773bda68-87c6-401a-b9a4-3dfb1f92e189",
		clientId: "8197dc5c-d047-4c90-a7d8-89c6c468d7c2",
		redirectUri: "https://dev-cstracking.niagarawater.com",
	},
	cache: {
		cacheLocation: "localStorage",
		storeAuthStateInCookie: true,
	},
};

// Authentication Parameters
const authenticationParameters = {
	scopes: ["user.read"],
};

// Options
const options = {
	loginType: LoginType.Redirect,
	tokenRefreshUri: window.location.origin + "/auth.html",
};

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options);
